import { defineComponent, onBeforeUnmount, onUnmounted, ref, watch } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import { req } from '@/api';

import { Btn, Tab } from '@/components/UI';

import Skeleton from './skeleton';

export default defineComponent({
	components: { Tab, Btn, Skeleton },
	directives: {
		spec(el) {
			el.querySelectorAll('a').forEach(a => {
				a.setAttribute('target', '_blank');
			});
		},
	},
	setup(props, { root }: any) {
		const { $message, $router } = root;
		const activeKey: Ref<string> = ref('me');
		const navigation: Ref<any[]> = ref([
			{
				key: 'me',
				title: '关于我',
				action: () => {
					//
					activeKey.value = 'me';
				},
				isActive: true,
			},
			{
				key: 'web',
				title: '关于本站',
				action: () => {
					activeKey.value = 'web';
					/*
                    return new Promise((resolve, reject) => {
                        $router
                            .push('/about/web')
                            .then(() => {
                                resolve('');
                            })
                            .catch((error: any) => {
                                reject('ignore');
                            });
                    });
                    */
				},
				isActive: false,
			},
			{
				key: 'statement',
				title: '免责声明',
				action: () => {
					activeKey.value = 'statement';
				},
				isActive: false,
			},
		]);
		/*
        watch(
            () => navigation,
            () => {
                console.log('发生了改变');
            }
        );
        */
		//页面跳转部分
		const to = (url: string): void => {
			$router
				.push({
					path: '' + url,
				})
				?.then(() => {
					//$message.success('跳转成功');
				})
				?.catch(() => {
					$message.error('暂不支持');
				});
		};
		/*
        //const md = (marked as any)('# 1## 22### 333');
        let md = "<script>alert('1')</script>";
        //console.log(marked);
        req('ABOUT_ALL')
            .then(data => {
                console.log(data);
            })
            .catch(error => $message.error(error));
        const converter = new (showdown as any).Converter();
        md = converter.makeHtml(`
# 这是标题
## 这是二级标题
去你妈的 [去你妈的](/static "_blank")
        `);
        */
		//功能块：获取markdown数据并进行解析
		const isLoading: Ref<boolean> = ref(true);
		const md: Ref<any> = ref({
			me: '',
			web: '',
			statement: '',
		});
		req('ABOUT_ALL')
			.then((data: any) => {
				let { md: markdown } = data;
				if (typeof markdown !== 'object') {
					markdown = {};
				}
				for (const key in markdown) {
					if (typeof markdown[key] !== 'string') {
						markdown[key] = '';
						//delete markdown[key];
					}
				}
				md.value = { ...md.value, ...markdown };
			})
			.catch(error => $message.error('无法获取数据：' + error))
			.finally(() => {
				isLoading.value = false;
			});
		const clickForCopy = (event: any) => {
			//console.log('执行拷贝');
			$message.warning('思想有多远，你就给我滚多远');
		};
		/*
        setInterval(() => {
            console.log(document.querySelector('code'));
        }, 1000);
        */
		/*
        watch([() => md.value, () => activeKey.value], value => {
            setTimeout(() => {
                /\*
                console.log(
                    'md发生变化，开始执行',
                    document.querySelectorAll('code')
                );
                *\/
            }, 1);
            document.querySelectorAll('code').forEach(item => {
                //console.log('发现了元素');
                item.addEventListener('click', clickForCopy);
            });
        });
        onBeforeUnmount(() => {
            //console.log('组件销毁之前');
            document.querySelectorAll('code').forEach(item => {
                item.removeEventListener('click', clickForCopy);
            });
        });
        */
		return { navigation, activeKey, to, isLoading, md };
	},
});
