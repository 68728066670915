

















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
	setup() {
		const textfill = (num: number) => {
			const nullText = '-';
			return Array(Math.floor(num)).fill(nullText).join('');
		};
		return { textfill };
	},
});
