import { defineComponent, PropType } from '@vue/composition-api';

import PageNodeMain from '@/components/PageNodeMain';
import PageNodeSub from '@/components/PageNodeSub';
import Card from '@/components/Card';
import Profile from '@/components/Article/Profile';

import AboutMe from '@/components/Article/AboutMe';
//import Tab from '@/components/Tab/index.vue';

//import { req } from '@/api';
import LoveUU from '@/components/Article/LoveUU';

export default defineComponent({
    components: {
        PageNodeMain,
        PageNodeSub,
        Card,
        Profile,
        AboutMe,
        //Tab,components: { LoveUU },
        LoveUU,
    },
    setup(props, { root }: any) {
        return {};
    },
});
